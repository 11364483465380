import React, { useState } from "react";
import Logo from "../images/logo.png";


const Header = () => {

    const scrollTo = (id) => {
        let destinationDiv = "";
        // Find the destination div by its id
        if (id === "apply") {
          destinationDiv = document.getElementById("started");
        } else if (id === "how") {
          destinationDiv = document.getElementById("howSection");
        } else if (id === "faqs") {
          destinationDiv = document.getElementById("faqs");
        }
    
        // Scroll to the destination div smoothly
        if (destinationDiv) {
          destinationDiv.scrollIntoView({ behavior: "smooth" });
        }
      };
  return (
    <>
      <nav class="navbar">
        <div class="container">
          <div id="navMenu" class="navbar navbar-menu-open">
            <div class="navbar-start">
              <img src={Logo} alt="Logo" />
            </div>

            <div class="navbar-end">
              <div class="navbar-item">
                <div class="buttons">
                  <a class="navbar-item" onClick={() => scrollTo("faqs")}>
                    Company Profile
                  </a>
                  <a class="navbar-item" onClick={() => scrollTo("how")}>
                    How it works?
                  </a>
                  <a
                    class="button is-dark"
                    style={{ borderRadius: "2em" }}
                    onClick={() => scrollTo("apply")}
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Header;
