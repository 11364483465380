// src/App.js
import React, { useEffect } from "react";
import "./App.css";
import "./styling.css";
import { Route, Routes } from "react-router-dom";
import OnboardClientsHCP from "./components/OnboardClientHCP";
import LandingUI from "./components/landingUI";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ReactGA from "react-ga";
import PaymentStatusFailureIpe from "./components/PaymentStatusFailureIpe";
import PaymentStatus from "./components/PaymentStatus";
import PrivacyPolicyHCP from "./components/PrivacyPolicyHCP";
import PaymentStatusFailureTimeout from "./components/PaymentStatusFailureTimeout";
import PaymentStatusFailureDeclined from "./components/PaymentStatusFailureDeclined";
import PaymentStatusServerError from "./components/PaymentStatusFailureServerError";

function App() {
  const id = process.env.TRACKING_ID
  useEffect(() => {
    ReactGA.initialize();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<LandingUI />} />
        <Route path="/hcp" element={<OnboardClientsHCP />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/hcp/privacy-policy" element={<PrivacyPolicyHCP />} />
        <Route path="/payments/status/:status/:transactionID/:transactionTime" element={<PaymentStatus />} />
        <Route path="/payments/ipe/:transactionTime" element={<PaymentStatusFailureIpe />} />
        <Route path="/payments/server-error/:transactionTime" element={<PaymentStatusServerError />} />
        <Route path="/payments/timedout/:transactionTime" element={<PaymentStatusFailureTimeout />} />
        <Route path="/payments/declined/:transactionTime" element={<PaymentStatusFailureDeclined />} />
      </Routes>
    </>
  );
}

export default App;
