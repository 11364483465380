import Logo from "../components/landingUI/logo.png";
import { useEffect, useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  
  const navigate = useNavigate();


  return (
    <>
      <div className="container">
        {/* Header */}
        <nav class="navbar logo-nav-padding">
          <div class="container">
            <div id="navMenu" class="navbar navbar-menu-open">
              <div class="navbar-start">
                <img src={Logo} alt="Logo" />
              </div>

              <div class="navbar-end">
                <div class="navbar-item">
                  <div class="buttons">
                    <a
                      class="button is-dark"
                      style={{ borderRadius: "2em" }}
                      onClick={() => navigate("/",  {state: "takeToReserve"})}
                    >
                      Reserve Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div class="container has-text-centered" style={{ padding: "3em 0" }}>
        <h1 style={{ fontSize: "xx-large" }}>
          <b>
            <u>
              Privacy Policy for "Bookiteazy CRYG Technologies LLP" Coming Soon
              Page
            </u>
          </b>
        </h1>
        <br></br>
        <p style={{ textAlign: "justify" }}>
          Effective Date: 9-10-2023
          <br></br>
          <br></br>
          Thank you for your interest in Bookiteazy CRYG Technologies LLP. This
          Privacy Policy outlines how we collect, use, and protect the
          information you provide on our "Coming Soon" page.
        </p>
        <br></br>
        <h3 style={{ textAlign: "start" }}>
          <b>1. Information We Collect</b>
        </h3>
        <p style={{ textAlign: "justify" }}>
          We collect the following information when you provide it to us on our
          "Coming Soon" page:
          <ul style={{ textAlign: "justify", textDecoration: "underline" }}>
            <li>Full Name</li>
            <li>Number</li>
            <li>Profession</li>
            <li>Email</li>
          </ul>
        </p>
        <br></br>

        <h3 style={{ textAlign: "start" }}>
          <b>2. How We Use Your Information</b>
        </h3>

        <p style={{ textAlign: "justify" }}>
          The information collected is used solely for the purpose of sending
          you updates and announcements related to the launch of Bookiteazy CRYG
          Technologies LLP. We do not share, sell, or distribute your
          information to third parties.
        </p>
        <br></br>
        <h3 style={{ textAlign: "start" }}>
          <b>3. Data Security</b>
        </h3>

        <p style={{ textAlign: "justify" }}>
          We implement appropriate security measures to protect the
          confidentiality, integrity, and availability of your information.
          However, please be aware that no method of transmission over the
          internet or electronic storage is 100% secure.
        </p>
        <br></br>
        <h3 style={{ textAlign: "start" }}>
          <b>4. Cookies and Analytics</b>
        </h3>
        <p style={{ textAlign: "justify" }}>
          We do not use cookies or analytics on our "Coming Soon" page.
        </p>
        <br></br>
        <h3 style={{ textAlign: "start" }}>
          <b>5. Your Rights</b>
        </h3>
        <p style={{ textAlign: "justify" }}>
          You have the right to access, correct, or delete your personal
          information. You can do so by contacting us at contact@bookiteazy.com.
          <br></br>
          <br></br>
          <h3 style={{ textAlign: "start" }}>
            <b>6. Changes to this Privacy Policy</b>
          </h3>
          <p style={{ textAlign: "justify" }}>
            This Privacy Policy may be updated periodically to reflect changes
            in our practices. The updated policy will be effective immediately
            upon posting.
          </p>
        </p>
      </div>

      {/* Footer */}
      <footer class="footer">
        <div class="content has-text-centered container">
          <p>
            <p style={{ textAlign: "left" }}>
              contact us: contact@bookiteazy.com
              <hr></hr>
              <p style={{ textAlign: "left", lineHeight: "1.4" }}>
                <strong>
                  <a href="https://linkedin.com/company/bookiteazy-technologies">
                    Bookiteazy Technologies
                  </a>
                </strong>{" "}
                by Copyright © 2023 Bookiteazy CRYG Technologies LLP. All rights
                reserved. <p style={{ textAlign: "right" }}>India</p>
              </p>
            </p>
            <p style={{ textAlign: "left", color: "#4a4a4a" }}>
              <a
                style={{ color: "#4a4a4a" }}
                onClick={() => navigate("/")}
              >
                <u>back to home</u>
              </a>{" "}
              {/* |{" "}
              <a
                style={{ color: "#4a4a4a" }}
                onClick={() => navigate("/privacy-policy")}
              >
                Other Policies
              </a> */}
            </p>
          </p>
        </div>
      </footer>
    </>
  );
};

export default PrivacyPolicy;
