import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyBFMgzsdbasxqvebpX-9lo9r382GFU7c6w',
    authDomain: 'eazy-daff5.firebaseapp.com',
    databaseURL: 'https://eazy-daff5-2fdde.asia-southeast1.firebasedatabase.app',
    projectId: 'eazy-daff5-2fdde',
    storageBucket: 'eazy-daff5-2fdde.appspot.com',
    messagingSenderId: '578688124556',
    appId: '1=578688124556=web=41d2712c5a0a9986395da9',
    measurementId: 'G-RXFSFHL98B',
  };

  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);
  
  export default db;