import Video from "./promo_video.mp4";
import Logo from "./logo.png";
import Features from "./image_1.png";
import { useEffect, useState } from "react";
import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { set, ref } from "firebase/database";
import { getDatabase } from "firebase/database";
import db from "../../API/firebase.js";
import ReactGA from 'react-ga';

const LandingUI = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    number: "",
    professional: "",
  });
  const [errorFields, setErrorFields] = useState([]);
  const [dataSuccess, setDataSuccess] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const navigate = useNavigate();

  const handleVideoLoadedData = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    setIsVideoPlaying(true);

    return () => {
      setIsVideoPlaying(false);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const scrollTo = (id) => {
    ReactGA.event({
        category: 'ReserveNow Interaction', // Category of the event
        action: 'Button Clicked',      // Action of the event
        label: 'Reserve Now',    // Optional label to provide additional information
      });
    let destinationDiv = "";
    // Find the destination div by its id
    if (id === "reserveNow") {
      destinationDiv = document.getElementById("reserveNow");
    }

    // Scroll to the destination div smoothly
    if (destinationDiv) {
      destinationDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  function checkErrors() {
    const errors = [];

    if (!formData.fullName) errors.push("Full Name");
    if (!formData.professional) errors.push("Profession");
    if (!formData.email) errors.push("Email");
    if (!formData.number) errors.push("Number");
    if (formData.number && formData.number.length !== 10)
      errors.push("Only 10 digits Contact Number allowed!");

    setErrorFields(errors);
    return errorFields;
  }

  const handleSubmit = (event) => {
    const err = checkErrors();

    setDataSuccess(false);

    if (
      formData.fullName &&
      formData.email &&
      formData.number &&
      formData.professional &&
      formData.number.length === 10
    ) {
      var fullName = formData.fullName;
      var number = formData.number;
      var email = formData.email;
      var professional = formData.professional;
      event.preventDefault();
      set(ref(db, "user/" + fullName), {
        fullName: fullName,
        number: number,
        email: email,
        professional: professional,
      })
        .then(() => {
          setDataSuccess(true);
          setFormData({
            fullName: "",
            email: "",
            number: "",
            professional: "",
          });
        })
        .catch((err) => {
          setDataSuccess(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="container">
        {/* Header */}
        <nav className="navbar logo-nav-padding">
          <div className="container">
            <div id="navMenu" className="navbar navbar-menu-open">
              <div className="navbar-start">
                <img src={Logo} alt="Logo" />
              </div>

              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="buttons">
                    <a
                      className="button is-dark"
                      style={{ borderRadius: "2em" }}
                      onClick={() => scrollTo("reserveNow")}
                    >
                      Reserve Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* Showcase Video */}
        <section className="hero is-halfheight">
          <div className="hero-body">
            <div
              style={{
                width: !isVideoLoaded && "100%",
                backgroundColor: !isVideoLoaded && "beige",
                padding: !isVideoLoaded && "8em",
                borderRadius: !isVideoLoaded && "2em",
              }}
            >
              {!isVideoLoaded && (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <h1>
                    {" "}
                    "SIMPLE IS GOOD" <br></br>
                    WWW.BOOKITEAZY.COM
                  </h1>
                  {/* You can replace this with a loading spinner component */}
                </div>
              )}
              <video
                autoPlay
                muted
                controls
                style={{ width: "100%", height: "auto", borderRadius: "2em" }}
                src={Video}
                onLoadedData={handleVideoLoadedData}
              >
                Your browser does not support the video tag.
              </video>
              {/* <p className="subtitle">Half height subtitle</p> */}
            </div>
          </div>
        </section>

        {/* <nav className="level is-mobile">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Tweets</p>
              <p className="title">3,456</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Following</p>
              <p className="title">123</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Followers</p>
              <p className="title">456K</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Likes</p>
              <p className="title">789</p>
            </div>
          </div>
        </nav> */}
        <h1 className="title is-4rem mb-0 text-centered has-text-centered mb-6">
          Your Time. Your Terms.
        </h1>
        <h2
          className="subtitle is-1 has-text-centered"
          style={{ marginBottom: "4rem" }}
        >
          Groceries bookings on your tap with Bookiteazy.
        </h2>

        {/* Features */}
        <nav className="level mb-6" style={{ justifyContent: "space-around" }}>
          <div className="level-item has-text-centered">
            <div>
              <svg
                style={{
                  backgroundColor: "gold",
                  borderRadius: "1em",
                  padding: "-1px 3px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                height="60"
                width="60"
                viewBox="0 0 448 512"
              >
                <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
              </svg>
              <p className="subtitle is-4 has-text-centered">MORE.</p>
              <p className="subtitle is-4 has-text-centered">PRIVACY.</p>
              {/* <p className="title">3,456</p> */}
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <svg
                style={{
                  backgroundColor: "lavender",
                  borderRadius: "1.2em",
                  padding: "-1px 3px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                height="60"
                width="60"
                viewBox="0 0 576 512"
              >
                <path d="M256 0h64c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H256c-17.7 0-32-14.3-32-32V32c0-17.7 14.3-32 32-32zM64 64H192v48c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V64H512c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64zM176 437.3c0 5.9 4.8 10.7 10.7 10.7H389.3c5.9 0 10.7-4.8 10.7-10.7c0-29.5-23.9-53.3-53.3-53.3H229.3c-29.5 0-53.3 23.9-53.3 53.3zM288 352a64 64 0 1 0 0-128 64 64 0 1 0 0 128z" />
              </svg>
              <p className="subtitle is-4 has-text-centered">DIGITAL.</p>
              <p className="subtitle is-4 has-text-centered">ONLY.</p>
              {/* <p className="title">123</p> */}
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <svg
                style={{
                  backgroundColor: "skyblue",
                  borderRadius: "1em",
                  padding: "-1px 3px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                height="60"
                width="60"
                viewBox="0 0 448 512"
              >
                <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z" />
              </svg>
              <p className="subtitle is-4 has-text-centered">ANYWHERE.</p>
              <p className="subtitle is-4 has-text-centered">ANYTIME.</p>
              {/* <p className="title">456K</p> */}
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <svg
                style={{
                  backgroundColor: "yellowgreen",
                  borderRadius: "1.2em",
                  padding: "-1px 3px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                height="60"
                width="60"
                viewBox="0 0 512 512"
              >
                <path d="M320 96H192L144.6 24.9C137.5 14.2 145.1 0 157.9 0H354.1c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128H320c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96H96c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4l0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V424c0 11 9 20 20 20s20-9 20-20V410.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l0 0-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V216z" />
              </svg>
              <p className="subtitle is-4 has-text-centered">CASHBACKS.</p>
              <p className="subtitle is-4 has-text-centered">REWARDS.</p>
              {/* <p className="title">456K</p> */}
            </div>
          </div>
        </nav>

        <section className="hero is-medium">
          <div
            className="hero-body"
            style={{ textAlign: "center", display: "block" }}
          >
{/* <img src={Features} /> */}
            {/* <p className="subtitle">Half height subtitle</p> */}
          </div>
        </section>

        {/* fill form */}
        <div className="tile" id="reserveNow">
          <div className="tile is-parent is-vertical mb-6">
            <article
              className="tile is-child notification"
              style={{
                justifyContent: "center",
                background: "#f5f5f5",
              }}
            >
              <div className="columns">
                <div className="column">
                  {" "}
                  <h2
                    className="subtitle is-1 has-text-centered center-embark"
                    style={{
                      display: "contents",
                    }}
                  >
                    Embark on the journey to seamless scheduling.<br></br>
                    <b> Get Early Access!</b>
                  </h2>
                </div>
                {dataSuccess && (
                  <>
                    {" "}
                    <div
                      className="column"
                      style={{
                        color: "forestgreen",
                        backgroundColor: "gold",
                        borderRadius: "2em",
                      }}
                    >
                      <h2
                        className="subtitle is-1 has-text-centered center-embark"
                        style={{
                          display: "contents",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="26"
                          width="26"
                          viewBox="0 0 512 512"
                        >
                          <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm177.6 62.1C192.8 334.5 218.8 352 256 352s63.2-17.5 78.4-33.9c9-9.7 24.2-10.4 33.9-1.4s10.4 24.2 1.4 33.9c-22 23.8-60 49.4-113.6 49.4s-91.7-25.5-113.6-49.4c-9-9.7-8.4-24.9 1.4-33.9s24.9-8.4 33.9 1.4zm40-89.3l0 0 0 0-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2 0 0 0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8l0 0 0 0zm160 0l0 0-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2 0 0 0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8l0 0 0 0 0 0z" />
                        </svg>
                        &nbsp;Thanks for your reservation.<br></br>
                        <b> Stay tuned for Early Access benefits!</b>
                      </h2>
                    </div>
                  </>
                )}
                <div
                  className="column"
                  style={{ display: dataSuccess && "none" }}
                >
                  <div className="field mr-1">
                    <label className="label">Full Name</label>
                    <div className="control">
                      <input
                        onChange={handleInputChange}
                        className="input"
                        name="fullName"
                        type="text"
                        placeholder="Chintan Raval"
                      />
                    </div>
                  </div>
                  <div className="field mr-1 ">
                    <label className="label">Profession</label>
                    <div className="select is-normal">
                      <select
                        name="professional"
                        onChange={handleInputChange}
                        style={{ maxWidth: "100%", width: "25em" }}
                      >
                        <option value={""} disabled selected>
                          --Select--
                        </option>
                        <option value={"Student"}>Student</option>
                        <option value={"Working Professional"}>
                          Working Professional
                        </option>
                        <option value={"Business Professional"}>
                          Business Professional
                        </option>
                      </select>
                    </div>
                  </div>{" "}
                </div>
                <div
                  className="column"
                  style={{ display: dataSuccess && "none" }}
                >
                  {" "}
                  <div className="field mr-1 ">
                    <label className="label">Number</label>
                    <div className="control">
                      <input
                        onChange={handleInputChange}
                        className="input"
                        type="text"
                        name="number"
                        placeholder="Enter 10 digits only"
                      />
                    </div>
                  </div>
                  <div className="field mr-1 ">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        onChange={handleInputChange}
                        className="input"
                        type="email"
                        name="email"
                        placeholder="Email id"
                      />
                    </div>
                  </div>
                  {errorFields.length > 0 && (
                    <p
                      style={{
                        width: "23em",
                        color: "red",
                        fontWeight: "400",
                      }}
                      className="mb-2"
                    >
                      # Please fill out fields:
                      <b> {errorFields.join(", ")}</b>
                    </p>
                  )}
                  <div className="field mr-1" style={{ textAlign: "right" }}>
                    <p className="control">
                      <a className="button is-dark" onClick={handleSubmit}>
                        Reserve Now
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="footer">
        <div className="content has-text-centered container">
          <p>
            <p style={{ textAlign: "left" }}>
              contact us: contact@bookiteazy.com
              <hr></hr>
              <p style={{ textAlign: "left", lineHeight: "1.4" }}>
                <strong>
                  <a href="https://linkedin.com/company/bookiteazy-technologies">
                    Bookiteazy Technologies
                  </a>
                </strong>{" "}
                by Copyright © 2023 Bookiteazy CRYG Technologies LLP. All rights
                reserved. <p style={{ textAlign: "right" }}>India</p>
              </p>
            </p>
            <p style={{ textAlign: "left", color: "#4a4a4a" }}>
              <a
                style={{ color: "#4a4a4a" }}
                onClick={() => navigate("/privacy-policy")}
              >
                <u>Privacy Policy</u>
              </a>
              {/* <|  <a  style={{ color:"#4a4a4a" }} href="https://linkedin.com/company/bookiteazy-technologies">
                    Other Policies
                  </a>> */}
            </p>
          </p>
        </div>
      </footer>
    </>
  );
};

export default LandingUI;
